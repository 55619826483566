<template>
    <div class="col-12 order-3 order-md-1 module navigation">
        <h2>Navigation</h2>
        <div class="row">
            <div class="col-12 badge clickable"
                :class="ui.activeTab === 'books' ? 'bg-success' : 'bg-secondary'"
                @click="$emit('change-active-tab', 'books')"
            >
                <span>Books</span>
            </div>
            <div class="col-12 badge clickable"
                :style="!listIsLoaded ? 'filter:brightness(.5)' : '' "
                :class="ui.activeTab === 'vocab' ? 'bg-success' : 'bg-secondary'"
                @click="$emit('change-active-tab', 'vocab')"
            >
                <span>Vocab</span>
            </div>
            <div class="col-12 badge clickable"
                :style="!listIsLoaded ? 'filter:brightness(.5)' : '' "
                :class="ui.activeTab === 'grid' ? 'bg-success' : 'bg-secondary'"
                @click="$emit('change-active-tab', 'grid')"
            >
                <span>Grid</span>
            </div>
            <div class="col-12 badge clickable"
                :class="ui.activeTab === 'help' ? 'bg-success' : 'bg-secondary'"
                @click="$emit('change-active-tab', 'help')"
            >
                <span>Help</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navigate",
    props: [
        'ui',
        'listIsLoaded'
    ]
}
</script>
