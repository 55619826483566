<template>
    <div class="tile">
        <span :style="handled ? 'text-decoration: line-through; opacity: .7' : ''">{{card.word}}</span>
        <i v-if="selected" class="fas fa-check" style="padding-left: .25em"></i>
        <i v-if="trashed" class="fas fa-trash" style="padding-left: .25em"></i>
    </div>
</template>

<script>
export default {
    name: "Tile",
    computed: {
        handled() {
            if (
                this.trashed
            ) return true
            return false
        }
    },
    props: [
        'card',
        'selected',
        'trashed'
    ]
}
</script>
