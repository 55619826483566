<template>
<span lang="ja"
    class="header clickable badge"
    :class="badgeColor"
    @click="changeActiveEntry"
>
    <!-- toggle an entry -->
    <span class="fa-stack fa buttons"
        @click="markEntry()"
    >
        <i class="far fa-square fa-stack-2x"></i>
        <i :style="setVisibility" class="fa fa-check fa-stack-1x c-white"></i>
    </span>
    <!-- show entry reading and commonality -->
    <span>{{showEntryReading}}</span>
    <div v-if="determineIfCommonalityIsShown"
        class="progress w-100"
        style="height:5px;"
        v-tippy content="Comparison of word frequencies to first (most popular) match. This is showing because some entries have been determined to be unlikely."
    >
        <div class="progress-bar"
            role="progressbar"
            :class="entryBarColor"
            :style="`width:${determineCommonalityWidth};`"
        >
        </div>
    </div>
</span>
</template>

<script>
export default {
    name: 'ReadingBadge',
    props: [
        'activated',
        'selected',
        'active',
        'entries',
        'index'
    ],
    computed: {
        badgeColor() {
            if (this.activated) return 'bg-purple'
            return 'bg-secondary'
        },
        showEntryReading() {
            const matchType = this.active.match
            const { reading, entry } = this.entries
            if (matchType === 'exact') return reading
            return entry
        },
        determineIfCommonalityIsShown() {
            const { entries } = this.active
            const threshold = 0.7
            if (entries.length > 1 && entries.some((i) => i.somethingelse / entries[0].somethingelse < threshold)) return true
            return false
        },
        determineCommonalityWidth() {
            const m = (this.entries.somethingelse / this.active.entries[0].somethingelse) * 100
            return `${m}%`
        },
        entryBarColor() {
            // eslint-disable-next-line no-mixed-operators
            const prog = this.entries.somethingelse / this.active.entries[0].somethingelse * 100
            if (prog > 70) return 'bg-success'
            if (prog > 15) return 'bg-warning'
            return 'bg-danger'
        },
        setVisibility() {
            if (this.selected) return 'opacity:1'
            return 'opacity:0'
        }
    },
    methods: {
        markEntry() {
            const { selected } = this
            const newVal = selected ? 0 : 1
            this.$emit('toggle-selection', newVal)
        },
        changeActiveEntry() {
            this.$emit('change-active-entry', this.index)
        },
    }
}
</script>
