<template>
    <div v-if="navWords.arr.length > 0">
        <div class="row w-100 pad-big">
            <!--navigation-->
            <div class="progress w-100 container-relative p-0">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :style="`width:${prog/len*100}%`"></div>
                <span class="pos-center">{{`${prog+1} / ${len}`}}</span>
            </div>
             <slot name="sidebar-modal-toggle-inner"></slot>
            <!--badge navigation-->
            <div class="d-none d-lg-block col align-right">
                <span lang="ja"
                    class="badge"
                    :class="items.ignored ? 'bg-warning' : learned.some( (i)=> i.match_type != 'kanji' && i.card == items.word ) ? 'bg-success' : 'bg-secondary'"
                    v-for="(items, indx) in nav.left"
                    @click="navBadgeClick(items.id)"
                    :key="indx"
                >
                    <span>{{items.word}}</span>
                    <span v-if="learned.includes(items.word)" class="fas fa-check"></span>
                </span>
            </div>
            <div class="d-none d-lg-block col">
                <span class="badge w-100"
                :class="learned.some( (i)=> i.match_type != 'kanji' && i.card == active.word ) ? 'bg-success' : 'bg-purple'"
                >
                    {{active.word}}
                </span>
            </div>
            <div class="d-none d-lg-block col align-left">
                <span class="badge"
                    :class="items.ignored ? 'bg-warning' : learned.some( (i)=> i.match_type != 'kanji' && i.card == items.word ) ? 'bg-success' : 'bg-secondary'"
                    v-for="(items, indx) in nav.right"
                    @click="navBadgeClick(items.id)"
                    :key="indx"
                >{{items.word}}</span>
            </div>
        </div>
    </div>
</template>

<script>
/*  old command on badge click:
@click="()=>{items.ignored ? clearSpecific(items.word, opts.lists[0].vocab_list) : ignoreWord(items.word, opts.lists[0].vocab_list)}"
*/
export default {
    name: 'Nav',
    methods: {
        navBadgeClick(item) {
            this.$emit('nav-bg-click', item)
            //  items.ignored ? clearSpecific(items.word, opts.lists[0].vocab_list) : ignoreWord(items.word, opts.lists[0].vocab_list)
            return true
        }
    },
    props: {
        prog: {
            default() {
                return 0
            }
        },
        len: {
            default() {
                return 0
            }
        },
        navWords: {
            default() {
                return { arr: [], active: "" }
            }
        },
        learned: {
            default() {
                return []
            }
        }
    },
    computed: {
        nav() {
            const { active, arr } = this.navWords
            return {
                left: arr.slice(0, active),
                active: arr[active] || null,
                right: arr.slice(active + 1, arr.length)
            }
        },
        active() {
            const { active, arr } = this.navWords
            return arr[active]
        }
    },
    created() {
        console.log('%cCreated list nav', window.ConsoleStyles.createdComponent, this)
    }
}
</script>
