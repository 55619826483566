<template>
    <div>
        <div class="col-12 book-card">
            <h2>Unfinished. This is only here because I wanna trash some words and I dun give a shitttt</h2>
            <button @click="trashWord">trash</button>
        </div>
        <div class="col-12 book-card align-center user-focus marg-big" style="min-height:300px;">
            <div class="row marg-big pad-big">
                <div
                    class="grid-tile col-6 col-md-4 col-lg-3 pad-small clickable"
                    v-for="(items, indx) in propWords.list"
                    :key="indx+'a'"
                    @click="toggle(items)"
                >
                    <Tile
                        class="w-100"
                        :card="items"
                        :selected="items.gridSelected"
                        :trashed="trashed.includes(items.word)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import Tile from './Tile.vue'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Grid',
    components: {
        Tile
    },
    data() {
        return {
            selected: [],
        }
    },
    computed: {
        trashed() {
            return this.learned.filter((i) => i.status === 'trashed').map((o) => o.card)
        }
    },
    methods: {
        toggle(item) {
            if (item.gridSelected) {
                item.gridSelected = false
            } else {
                this.$set(item, 'gridSelected', true)
            }
        },
        unselectAll() {
            this.propWords.list.forEach((i) => {
                if (i.gridSelected) i.gridSelected = false
            })
        },
        addWords(items) {
            console.table(this.propWords.list.filter((i) => i.gridSelected))
            return 123
        },
        async trashWord() {
            const wordsToTrash = this.propWords.list.filter((i) => i.gridSelected)
            const finalItem = wordsToTrash.map((i) => ({
                card: i.word,
                readings: ['none']
            }))
            const finalData = {
                items: finalItem,
                source: this.opts.lists[0].vocab_list
            }
            UseAPI("/create/add-trash", { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(dat)
            })
            .catch((dat) => {
                HandleRequestFail(dat)
            })
        },
        handleAddResponse(dat) {
            const { created, failure } = dat.modules
            const { options } = dat
            const emitArr = []
            if (created) {
                created.forEach((row) => {
                    const { item } = row
                    //  vm.ui.learned.push({
                    emitArr.push({
                        card: row.item.card,
                        status: row.q_state === 0 ? 'learned' : 'trashed',
                        id: row.id,
                        match_type: row.match_type
                    })
                })
            }
            this.$emit('learned-item', emitArr)
            if (failure && failure.length > 0) {
                const errMessage = `Error adding cards: ${failure.map((i) => i.card).toString()}.\nMost likely due to duplicate items. Check dev console for more info.`
                SendUserAlert(errMessage, 'alert-warning')
                console.warn('Error report', failure)
                console.log('Error log', JSON.stringify(failure), JSON.stringify(dat.warning))
                return false
            }
            this.unselectAll()
            return true
        },
    },
    props: {
        //  this is an array of objects so transformations will be two way
        hotkeysActive: {
            default: false
        },
        propWords: {
            type: Object,
            default() {
                console.log('No propWords found', 'this is an error.')
                return {
                    list: [{
                        word: "顔",
                        freq: "134",
                        match: "exact",
                        in_wk: null,
                        in_user: null,
                        entries: [{
                            entry: "顔", reading: "かお", definition: ["face", "visage", " look", "expression", "countenance", " honor", "honour", "face", " influence", "notoriety"], pos: "n", somethingelse: 721
                        }]
                    }],
                    modified: false
                }
            }
        },
        opts: {
            type: Object,
            default() {
                return {
                    freq: 0,
                    wk: 0,
                    interface: 'streamlined',
                    lists: []
                }
            }
        },
        learned: {
            type: Array,
            default() {
                return []
            }
        },
        ignoreList: {
            type: Object,
            default() {
                return []
            }
        }
    },
}
</script>

<style scoped lang="sass">
.grid-tile
    border-bottom: solid 2px rgba(0, 171, 255, 0.76)
    border-top: solid 2px rgba(0, 171, 255, 0.76)
    margin: -2px 0 0 -2px
    position: relative
.grid-tile:nth-child(2n)::after
    content: " "
    position: absolute
    width: 2px
    background-color: rgba(0, 171, 255, 0.76)
    right: 0
    top: 15%
    bottom: 15%
    height: 70%
.grid-tile::before
    content: " "
    position: absolute
    width: 2px
    background-color: rgba(0, 171, 255, 0.76)
    left: 0
    top: 15%
    bottom: 15%
    height: 70%
.grid-tile:hover
    background-color: #eee
</style>
