<template>
<div>
    <!--content-->
    <slot name="navigation"></slot>
    <div v-if="opts.lists.length !== 0 && ui.activeTab == 'books'" class="order-2 order-md-4 col-12 module">
        <slot name="heading"></slot>
        <!-- section 1 -->
        <div class="col-8 col-md-12 mx-auto">
            <label class="pad-small col-12 col-lg-6">Min Freq</label>
            <input type="number" class="col-12 col-lg-6 align-self-center" min="0" max="99" v-model.number="opts.freq" />
        </div>
        <div class="col-8 col-md-12 mx-auto">
            <label class="pad-small col-12 col-lg-6">WK Level</label>
            <input type="number" class="col-12 col-lg-6 align-self-center" min="0" max="60" v-model.number="opts.wk" />
        </div>
        <slot name="heading2"></slot>
        <!-- section 2 -->
        <div class="col-8 col-md-12 mx-auto row">
            <label class="pad-small col-12 col-lg-6">Automatically Generate List</label>
            <div class="col align-center align-self-center" >
                <span
                    class="fa-stack clickable"
                    @click="opts.genOnLoad = !opts.genOnLoad"
                >
                    <i class="far fa-square fa-stack-2x"></i>
                    <i v-show="opts.genOnLoad" class="fas fa-check fa-stack-1x c-white"></i>
                </span>
            </div>
        </div>
        <div class="col-8 col-md-12 mx-auto row">
            <label class="pad-small col-12 col-lg-6">Hints Enabled</label>
            <div class="col align-center align-self-center" >
                <span
                    class="fa-stack clickable"
                    @click="$emit('tutorial-off')"
                >
                    <i class="far fa-square fa-stack-2x"></i>
                    <i v-show="opts.tutorial" class="fas fa-check fa-stack-1x c-white"></i>
                </span>
            </div>
        </div>
        <div class="col-8 col-md-12 mx-auto row">
            <label class="pad-small col-12 col-lg-6">Usually Kana Behavior</label>
            <div class="col align-center align-self-center" >
                <span
                    class="fa-stack clickable"
                    @click="opts.usuallyKanaOverride = !opts.usuallyKanaOverride"
                >
                    <i class="far fa-square fa-stack-2x"></i>
                    <i v-show="opts.usuallyKanaOverride" class="fas fa-check fa-stack-1x c-white"></i>
                </span>
            </div>
        </div>
        <!-- usually kana explanation -->
        <OptionNote
            class="col-8 mx-auto"
            :val="opts.usuallyKanaOverride"
            :valMap="['Usually kana words will be added w/ readings enabled', 'Usually kana words will be added w/ readings disabled',]"
        >
            <slot slot="icon"><i class="fas fa-info-circle c-green"></i></slot>
        </OptionNote>
        <div
            v-if="opts.lists.length >= 2"
            class="col-8 col-md-12 mx-auto row"
        >
            <label class="pad-small col-12 col-lg-6">Show All Frequencies</label>
            <div class="col align-center align-self-center" >
                <span
                    class="fa-stack clickable"
                    @click="opts.allFrequencies = !opts.allFrequencies"
                >
                    <i class="far fa-square fa-stack-2x"></i>
                    <i v-show="opts.allFrequencies" class="fas fa-check fa-stack-1x c-white"></i>
                </span>
            </div>
        </div>
        <OptionNote
            v-if="opts.lists.length >= 2 && opts.allFrequencies"
            class="col-8 mx-auto"
            :val="opts.usuallyKanaOverride"
            :valMap="['', 'Showing frequencies for second and third books',]"
        >
            <slot slot="icon"><i class="fas fa-info-circle c-green"></i></slot>
        </OptionNote>
        <div v-if="ignoreList && ignoreList.length > 0" class="col-8 col-md-12 mx-auto row">
            <label class="pad-small col-12 col-lg-6">Reset ignores</label>
            <div class="col align-center align-self-center" >
                <span v-tippy :content="`${ignoreList.length} word(s)`" class="fa-stack clickable" @click="handleResetIgnores()">
                    <i class="fa-stack-2x fas fa-square"></i>
                    <i class="fa-stack-1x fas fa-eye-slash"></i>
                </span>
            </div>
        </div>
    </div>
    <div v-if="flags.made && ui.activeTab == 'vocab' && flags.lastSearch" class="col-12 order-1 order-md-2 module">
        <h2>Benchmarks</h2>
        <div class="w-75 mx-auto marg-big"><span class="badge bg-success help" style="width:100%" v-text="`${flags.lastSearch.title}`" v-tippy content="Primary book (determines order)"></span></div>
        <div class="w-75 mx-auto"><span class="badge bg-info help" style="width:100%" v-text="'unique '+ui.unique" v-tippy content="Number of unique words in book"></span></div>
        <div class="w-75 mx-auto"><span class="badge bg-purple help" style="width:100%" v-text="'generated '+ui.benchmark" v-tippy content="Number of unique words in book, accounting for set WK/freq levels"></span></div>
        <div class="w-75 mx-auto"><span class="badge bg-purple help" style="width:100%" v-text="'unknown '+ui.unknown" v-tippy content="Number of unknown words in list, at time of creation"></span></div>
        <div class="w-75 mx-auto marg-big"><span class="badge bg-success help" style="width:100%" v-text="'learned '+ui.learned.filter( (i)=> i.status == 'learned').length"></span></div>
        <div v-if="ui.ignored > 0" class="w-75 mx-auto"><span class="badge bg-success help" style="width:100%" v-text="'ignored '+ui.ignored"></span></div>
    </div>
    <div v-if="flags.made && ui.activeTab == 'vocab'" style="transition: 1s ease-out opacity" :style="flags.addedItems ? 'opacity:1' : 'opacity:0'"  class="col-12 order-4 order-md-3 module">
        <h2>To Lessons</h2>
        <div class="w-100 marg-big">
            <router-link to="/lessons/" data-dismiss="modal">
                <span class="fa-stack fa-2x clickable">
                    <i class="fa fa-square fa-stack-2x icon-background c-green"></i>
                    <i class="fas fa-home fa-stack-1x c-white"></i>
                </span>
            </router-link>
        </div>
        <div class="w-100"><p>Now that you've added a few words, you can start to learn them on the lessons page. Click the house to get started.</p></div>
    </div>
</div>
</template>

<script>
/* import Options from '../../Books/Options.vue' */
import OptionNote from './OptionNote.vue'

export default {
/*   components: {
      Options
    }, */
    name: 'Sidebar',
    props: [
        'ui',
        'words',
        'opts',
        'flags',
        'ignoreList'
    ],
    components: {
        OptionNote
    },
    methods: {
        handleResetIgnores() {
            if (confirm(`Reset ${this.ignoreList.length} ignored word(s) for primary book?`)) this.$emit('reset-ignores')
        }
    }
}
</script>
