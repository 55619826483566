function Hotkeys() {
    return {
        left: {
            keyup: () => this.changeActiveIndex(false)
        },
        a: {
            keyup: () => this.changeActiveIndex(false)
        },
        right: {
            keyup: () => this.changeActiveIndex(true)
        },
        d: {
            keyup: () => this.changeActiveIndex(true)
        },
        1: {
            keyup: () => {
                this.learned.find((i) => i.card === this.active.word)
                    ? this.undoAction()
                    : this.addWord(0)
            }
        },
        2: {
            keyup: () => {
                this.learned.find((i) => i.card === this.active.word)
                ? this.undoAction()
                : this.trashWord()
            }
        },
        3: {
            keyup: () => {
                this.learned.find((i) => i.card === this.active.word)
                ? this.undoAction()
                : this.ignoreWord(this.active)
            }
        },
        enter: {
            keyup: () => {
                this.active.entries[this.ui.activeEntry].selected ? this.active.entries[this.ui.activeEntry].selected = 0 : this.active.entries[this.ui.activeEntry].selected = 1
            }
        },
        tab: {
            keydown: (e) => {
                e.preventDefault()
                this.active.entries.length > this.ui.activeEntry + 1 ? this.ui.activeEntry += 1 : this.ui.activeEntry = 0
            }
        }
    }
}

export default Hotkeys
