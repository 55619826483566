<template>
    <span class="fa-stack">
        <span class="counter-text c-white">{{num}}</span>
        <i class="fa-stack-2x fas fa-circle c-blue"></i>
    </span>
</template>

<script>
export default {
    name: 'Counter',
    props: ['num']
}
</script>

<style lang="sass" scoped>
.counter-text
    z-index: 2
    position: absolute
    left: 0
    right: 0
    color: white!important
</style>
