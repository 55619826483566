<template>
<div>
    <div>
        <sub class="message"><slot name="icon"></slot> {{msg}}</sub>
    </div>
</div>
</template>

<script>
export default {
    name: 'OptionNote',
    computed: {
        msg() {
            const { val, valMap } = this
            if (!val) return valMap[0]
            if (val === true) return valMap[1]
            return valMap[val]
        }
    },
    props: ['val', 'valMap']
}
</script>

<style lang="scss" scoped>
sub.message {
    color: grey;
}
</style>
