<template>
<div>
    <div>
        <span class="badge bg-purple">{{`freq ${freq}`}}</span>
        <span class="badge bg-purple" v-if="freqB && freqB > 0" v-tippy content="frequency in second book">{{`f2 ${freqB}`}}</span>
        <span class="badge bg-purple" v-if="freqC && freqC > 0" v-tippy content="frequency in third book">{{`f3 ${freqC}`}}</span>
        <span
            v-if="match_type === 'phonetic'"
            class="badge bg-warning cursor-help"
            v-tippy
            content="This word appears in the book but does not have a direct match in the dictionary. Showing entries that match the item's reading."
        >phonetic</span>
    </div>
    <h2 lang="ja"
        class="d-block pad-big"
        :class="displaySmall ? 'small' : '' "
    >
        <span v-for="(characters, indx) in word"
            v-tippy
            :content="wanakana.isKanji(characters) ? 'Add kanji' : 'If this were a kanji you could click to add it'"
            :class="wanakana.isKanji(characters) ? 'hover_kanji clickable' : 'c-purple'"
            @click="addKanji(characters)"
            :key="indx"
        >{{characters}}</span>
    </h2>
</div>
</template>

<script>
import { isKanji } from 'wanakana';

const wanakana = { isKanji }

export default {
    name: 'Focus',
    props: {
        word: {
            type: String,
            default() {
                return 'Error'
            }
        },
        freq: {
            type: Number,
            default() {
                return 0
            }
        },
        freqB: {
            type: Number,
            default() {
                return 0
            }
        },
        freqC: {
            type: Number,
            default() {
                return 0
            }
        },
        match_type: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            wanakana
        }
    },
    computed: {
        displaySmall() {
            if (this.word.length >= 7) {
                return true
            }
            return false
        },
    },
    methods: {
        addKanji(characters) {
            this.$emit('add-kanji', characters)
        },
    }
}
</script>
