<template>
<div>
    <div class="pad-big container-relative">
        <img class="cover pad-big" :src="getImg(items)" @error="items.filename = 'cover.jpg' " />
    </div>
    <h1>{{title}}</h1>
    <div class="d-flex justify-around">
        <div v-if="indx==0">
            <div class="fa-stack fa buttons clickable" tabindex="0">
                <i class="fa fa-square fa-stack-2x c-green"></i>
                <i class="fa fa-crown fa-stack-1x c-white"></i>
            </div>
            <div>
                <span class="badge bg-success">Determines order of list</span>
            </div>
        </div>
        <div v-else>
            <div @click="$emit('set-primary', indx)" class="fa-stack fa buttons clickable" tabindex="0">
                <i class="fa fa-square fa-stack-2x icon-background"></i>
                <i class="fa fa-crown fa-stack-1x c-white"></i>
            </div>
            <div>
                <span class="badge bg-jet">Set Primary</span>
            </div>
        </div>
        <div>
            <div @click="$emit('remove-book', indx)" class="fa-stack fa buttons clickable" tabindex="0">
                <i class="fa fa-square fa-stack-2x icon-background"></i>
                <i class="fa fa-times fa-stack-1x c-white"></i>
            </div>
            <div>
                <span class="badge bg-jet">Remove Book</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export default {
    name: 'Cover',
    props: [
        'items',
        'indx'
    ],
    computed: {
        title() {
            const { items } = this
            if (!items || !items.title) return 'Book Title'
            if (items.title.length > 30) return `${items.title.slice(0, 29)}…`
            return items.title
        }
    },
    created() {
        console.log('%cCreated selection', window.ConsoleStyles.createdComponent, this)
    },
    methods: {
        getImg(items) {
            try {
                return require(`@/assets/images/covers/${items.filename ? items.filename : 'cover.jpg'}`)
            } catch (e) {
                console.warn(`Image not found ${items.filename} for book ${items.title}`)
                return require(`@/assets/images/covers/cover.jpg`)
            }
        }
    }
}
</script>
