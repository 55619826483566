<template>
    <div>
        <span v-if="hotkeysActive" v-hotkey="Keymap"></span>
        <!-- navigation bar -->
        <Nav
            class="col-12 mx-auto book-card align-center pad-big center-horizontal marg-big"
            :prog="ui.activeIndex"
            :len="words.length"
            :navWords="navWords"
            :learned="learned"
            v-on:nav-bg-click="navBadgeClick($event)"
        >
            <slot name="sidebar-modal-toggle-outer" slot="sidebar-modal-toggle-inner"></slot>
        </Nav>
        <!--based on active word -->
        <div class="col-12 book-card align-center user-focus marg-big" style="min-height:300px;">
            <!-- mobile word navigation -->
            <div class="row d-md-none marg-big">
                <div class="col-6 align-center clickable"
                    @click="changeActiveIndex(false)"
                >
                    <span class="fa-stack clickable">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa ${ui.activeIndex === 0 ? 'fa-step-backward' : 'fa-angle-double-left'} fa-stack-1x`"></i>
                    </span>
                </div>
                <div class="col-6 align-center clickable"
                    @click="changeActiveIndex(true)"
                >
                    <span class="fa-stack clickable">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa ${ui.activeIndex === words.length-1 ? 'fa-step-forward' : 'fa-angle-double-right'} fa-stack-1x`"></i>
                    </span>
                </div>
            </div>
            <div class="row pad-big">
                <!-- standard word navigation -->
                <div class="d-none d-md-flex col-2 align-center center-all">
                    <span class="fa-stack clickable" @click="changeActiveIndex(false)">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa ${ui.activeIndex === 0 ? 'fa-step-backward' : 'fa-angle-double-left'} fa-stack-1x`"></i>
                    </span>
                </div>
                <!-- large center graphic -->
                <Word id="word"
                    v-if="active"
                    class="col-11 col-sm-10 col-md mx-auto bord marg-big pad-big"
                    style="flex-direction:column"
                    :word="active.word"
                    :match_type="active.match"
                    :freq="parseInt(active.freq, 10) || 0"
                    :freqB="opts.allFrequencies ? parseInt(active.freqB || 0, 10) : 0"
                    :freqC="opts.allFrequencies ? parseInt(active.freqC || 0, 10) : 0"
                    v-on:add-kanji="addKanji($event)"
                />
                <!-- standard word navigation -->
                <div class="d-none d-md-flex col-2 align-center center-all">
                    <span class="fa-stack clickable" @click="changeActiveIndex(true)">
                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                        <i :class="`fa ${ui.activeIndex === words.length-1 ? 'fa-step-forward' : 'fa-angle-double-right'} fa-stack-1x`"></i>
                    </span>
                </div>
            <!--EOR-->
            </div>
            <div v-if="active" class="col">
                <div class="row pad-big" style="min-height:250px;">
                    <!-- readings -->
                    <div class="col-12 align-all mag-big">
                        <!-- individual reading entry badge -->
                        <ReadingBadge
                            v-for="(entries, indx) in active.entries"
                            :activated="indx === ui.activeEntry"
                            :selected="entries.selected"
                            :active="active"
                            :entries="entries"
                            :index="indx"
                            :key="indx+'entry'"
                            v-on:change-active-entry="changeActiveEntry($event)"
                            v-on:toggle-selection="entries.selected = $event"
                        />
                        <div class="w-100"></div>
                        <!-- show parts of speech -->
                        <PartsOfSpeech
                            :pos="active.entries[ui.activeEntry].pos.split(' ')"
                        />
                    </div>
                    <!--definitions-->
                    <Definitions
                        class="col-12 align-all marg-big"
                        :entries="active.entries[ui.activeEntry]"
                        :index="ui.activeEntry"
                    />
                    <!--streamlined definitions-->
                    <div v-if="0===1" class="col-12 col-lg-10 mx-auto align-all marg-big">
                            <span v-if="active.match == 'phonetic'" class="badge bg-jet">{{`read: ${active.entries[ui.activeEntry].reading !== '' ? active.entries[ui.activeEntry].reading : active.entries[ui.activeEntry].entry}`}}</span>
                            <span v-if="ui.activeIndex == 0" class="badge bg-jet">Mark word as unknown by pressing '1'</span>
                            <span v-if="ui.activeIndex == 0" class="badge bg-jet">Or mark as known with '2'</span>
                            <span v-if="ui.activeIndex == 0" class="badge bg-jet">Don't think too hard about it</span>
                            <span v-if="ui.activeIndex == 1" class="badge bg-jet">Subentries are organized by frequency</span>
                            <span v-if="ui.activeIndex == 1" class="badge bg-jet">When in doubt, add only the first one or two</span>
                            <span v-if="ui.activeIndex == 2" class="badge bg-jet">Definitions are disabled by default to save brain power</span>
                            <span v-if="ui.activeIndex == 3" class="badge bg-jet">If not having them fills you with dread, hover over the blue tip box</span>
                            <span v-if="ui.activeIndex == 4" class="badge bg-jet">When you've added some words, click the 'manage' button in the navigation</span>
                            <span class="badge bg-jet" v-tippy content="Find some words to learn, but don't tire yourself out yet. The next step will be learning the words you've marked as unknown."><i class="far fa-question-circle"></i></span>
                            <span class="badge bg-info" v-tippy content="To enable abbreviated definitions/pos go to the BOOKS tab and set interface to advanced"><i class="far fa-question-circle"></i></span>
                    </div>
                    <!--buttons-->
                    <div
                    v-if="!learned.some( (i)=>i.match_type !=='kanji' && i.card === active.word )"
                        class="col-12 col-lg-8 mx-auto center-all"
                        style="justify-content:space-evenly;align-self:flex-end;"
                    >
                            <!--unlearned-->
                            <BLabel
                                v-on:click="addWord(0)"
                                class="pad-big"
                                v-tippy
                                content="Want to learn (will become a lesson)"
                                style="min-width:5em;"
                                activated=1
                                :colors="{outer: 'c-purple', text: 'bg-purple'}"
                                icon="fa-plus"
                                label="add"
                            >
                                <slot
                                    v-if="active.match === 'phonetic' && numEntriesSelected > 1"
                                    slot="counter"
                                >
                                    <Counter
                                    class="phonetic-counter"
                                    :num="numEntriesSelected"
                                    v-tippy
                                    content="Since this is a phonetic match, this will create multiple cards - one for each selected entry."
                                    />
                                </slot>
                            </BLabel>
                            <BLabel
                                v-on:click="trashWord()"
                                class="pad-big"
                                v-tippy
                                content="Already know (will not show up in future vocab lists)"
                                style="min-width:5em;"
                                :colors="{outer: 'c-purple', text: 'bg-purple'}"
                                icon="fa-trash"
                                label="trash"
                            >
                                <slot
                                    v-if="active.match === 'phonetic' && numEntriesSelected > 1"
                                    slot="counter"
                                >
                                    <Counter
                                    class="phonetic-counter"
                                    :num="numEntriesSelected"
                                    v-tippy
                                    content="Since this is a phonetic match, this will trash multiple items - one for each selected entry."
                                    />
                                </slot>
                            </BLabel>
                            <BLabel
                                v-on:click="ignoreWord(active)"
                                class="pad-big"
                                v-tippy
                                content="Ignore (removes from list permanently, but will be unlearned)"
                                style="min-width:5em;"
                                :colors="{outer: 'c-purple', text: 'bg-purple'}"
                                icon="fa-eye-slash"
                                label="ignore"
                            />
                    </div>
                    <div v-else
                        v-for="(item, indx) in [learned.find(i=>i.card == active.word)]"
                        class="col-12 center-all"
                        :key="indx"
                    >
                        <BLabel
                            class="pad-big"
                            v-tippy
                            content="Completed"
                            style="min-width:5em;"
                            :colors="{outer: 'c-jet', text: 'bg-jet'}"
                            :activated="true"
                            :icon="`fa-${item.status == 'ignored' ? 'eye-slash' : item.status == 'trashed' ? 'trash' : 'plus'}`"
                            :label="item.status"
                        />
                        <BLabel
                            v-on:click="undoAction()"
                            class="pad-big"
                            v-tippy
                            content="Restore item to original state"
                            style="min-width:5em;"
                            :colors="{outer: 'c-purple', text: 'bg-purple'}"
                            icon="fa-redo-alt"
                            label="Undo"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as wanakana from 'wanakana'
import Vue from "vue";
import VueHotkey from 'v-hotkey'
import ApiPreloader from '@/assets/preloader'
import C from '@/assets/common'
//  import Lockr from 'lockr'
import Nav from './Nav.vue'
import Word from './Word.vue'
import ReadingBadge from './ReadingBadge.vue'
import PartsOfSpeech from './PartsOfSpeech.vue'
import Definitions from './Definitions.vue'
import Counter from './Counter.vue'
import BLabel from '../../Shared/ButtonWithLabel.vue'
import Keymap from './hotkeys'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C
Vue.use(VueHotkey)

export default {
    name: 'List',
    components: {
        Nav,
        Word,
        ReadingBadge,
        PartsOfSpeech,
        Definitions,
        BLabel,
        Counter,
    },
    created() {
        console.log('%cCreated vocabulary list', window.ConsoleStyles.createdComponent, this)
        // apply transformations to propWords
    },
    methods: {
        changeActiveIndex(forward = true) {
            const { ui, words, active } = this
            if (typeof ui !== 'object' || typeof words !== 'object' || typeof active !== 'object') {
                console.log('error with change index', ui, words, active)
                return false
            }
            if (forward) {
                do { ui.activeIndex !== words.length - 1 ? ui.activeIndex += 1 : ui.activeIndex = 0 } while (this.words[ui.activeIndex].ignored)
            } else {
                do { ui.activeIndex !== 0 ? ui.activeIndex -= 1 : ui.activeIndex = words.length - 1 } while (this.words[ui.activeIndex].ignored)
            }
            ui.activeEntry = 0
            this.active = words[ui.activeIndex]
            return words[ui.activeIndex]
        },
        changeActiveEntry(indx) {
            this.ui.activeEntry = indx
        },
        resetUi() {
            console.log('resetting ui')
            this.ui.activeIndex = 0
            // while(this.words[this.ui.activeIndex].ignored) this.ui.activeIndex++
            this.ui.activeEntry = 0
            this.active = this.words[0]
            this.$emit('ui-was-reset')
        },
        checkIfUsuallyKana(entry) {
            //  if the override is disabled, then we dont care anyway
            if (!this.opts.usuallyKanaOverride) return false
            const pos = entry.pos.split(' ')
            if (pos.includes('uk')) return true
            return false
        },
        //  API CALLS
        addKanji(kanji) {
            if (!wanakana.isKanji(kanji)) {
                SendUserAlert("That's not a kanji", 'alert-warning')
                return
            }
            const finalItem = {
                card: kanji,
                readings: kanji
            }
            const finalData = {
                items: [finalItem],
                source: this.opts.lists[0].vocab_list
            }
            UseAPI('/create/add-kanji', { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                if (this.handleAddResponse(dat)) {
                    SendUserAlert('Kanji added', 'alert-success')
                }
            })
            .catch((dat) => {
                HandleRequestFail(dat)
            })
        },
        async addPhonetic(item, qState) {
            const items = []
            item.entries.forEach((entry) => {
                if (entry.selected) {
                    items.push({
                        card: entry.entry,
                        q_state: qState,
                        readings: !this.checkIfUsuallyKana(entry) ? entry.reading : ''
                    })
                }
            })
            // trash the phonetic guess entry (that isnt a real word)
            const finalData = {
                items,
                source: this.opts.lists[0].vocab_list,
                list_word: {
                    card: item.word,
                    readings: 'none'
                }
            }
            UseAPI('/create/add-phonetic', { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(dat)
                // also clear preload data
                ApiPreloader.clearApiKey('/lessons')
            })
            .catch((dat) => {
                HandleRequestFail(dat)
            })
        },
        async addExact(item) {
            const finalItem = {
                card: item.word,
                readings: []
            }
            let anyItemIsUsuallyKana = false
            item.entries.forEach((entry) => {
                if (this.checkIfUsuallyKana(entry)) anyItemIsUsuallyKana = true
                if (entry.selected) finalItem.readings.push(entry.reading)
            })
            //  override if any item is usually kana
            finalItem.readings = !anyItemIsUsuallyKana ? finalItem.readings.toString() : ''
            const finalData = {
                items: [finalItem],
                source: this.opts.lists[0].vocab_list
            }
            UseAPI("/create/add-exact", { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(dat)
                // also clear preload data
                ApiPreloader.clearApiKey('/lessons')
            })
            .catch((dat) => {
                HandleRequestFail(dat)
            })
        },
        async addWord(qState) {
            const activeIndex = this.ui.activeIndex
            const activeItem = this.words[activeIndex]
            activeItem.match === 'exact' ? this.addExact(activeItem, qState) : this.addPhonetic(activeItem, qState)
            this.changeActiveIndex(true)
        },
        async trashWord() {
            const item = this.words[this.ui.activeIndex]
            const finalItem = {
                card: item.word,
                readings: ['none']
            }
            const finalData = {
                items: [finalItem],
                source: this.opts.lists[0].vocab_list
            }
            this.changeActiveIndex(true)
            UseAPI("/create/add-trash", { method: "PUT", body: JSON.stringify(finalData) })
            .then((dat) => {
                this.handleAddResponse(dat)
            })
            .catch((dat) => {
                HandleRequestFail(dat)
            })
        },
        ignoreWord(active) {
            const emitArr = [{
                card: active.word,
                status: 'ignored',
                id: active.id,
                match_type: active.match
            }]
            this.changeActiveIndex(true)
            this.$emit('learned-item', emitArr)
            this.$emit('ignored-item', active.word)
        },
        undoAction() {
            const itemIndex = this.learned.findIndex((i) => i.card === this.active.word)
            if (itemIndex === -1) return
            const item = this.learned[itemIndex]
            switch (item.status) {
                case 'learned':
                    if (this.active.match === 'phonetic') {
                        SendUserAlert('Change reverted, however as the item was a non-exact match, it was restored to the list but any additionally added words will need to be removed manually.')
                    } else {
                        SendUserAlert('Change reverted.', 'alert-success')
                    }
                    this.startDelete([item.id])
                    this.$emit('undo-learn', itemIndex)
                break;
                case 'ignored':
                    this.$emit('clear-from-ignore', item)
                    this.$emit('undo-learn', item)
                    SendUserAlert('Change reverted.', 'alert-success')
                break;
                case 'trashed':
                    if (this.active.match === 'phonetic') {
                        SendUserAlert('Change reverted, however as the item was a non-exact match, it was restored to the list but any additionally added words will need to be removed manually.')
                    } else {
                        SendUserAlert('Change reverted.', 'alert-success')
                    }
                    //  this.startDelete([item.id], itemIndex)
                    this.startDelete([item.id])
                    this.$emit('undo-learn', itemIndex)
                break;
                default:
                break;
            }

            //  this.ui.learned.splice(itemIndex, 1)
        },
        startDelete(ids) {
            if (!Array.isArray(ids) || ids.length < 1) {
                SendUserAlert('No items selected', 'alert-warning')
                return false
            }
            const vm = this
            UseAPI("/delete/", {
                method: "DELETE",
                body: JSON.stringify({
                items: ids
                })
            })
            .then((dat) => dat, vm)
            .catch((dat) => {
                SendUserAlert('there was an error deleting the words\ncheck console for error logs', 'alert-danger')
                HandleRequestFail(dat)
                console.log('failed to delete; error log')
                console.log(JSON.stringify(dat))
            }, vm)
            return ids
        },
        handleAddResponse(dat) {
            const { created, failure } = dat.modules
            const { options } = dat
            const emitArr = []
            if (created) {
                created.forEach((row) => {
                    const { item } = row
                    //  vm.ui.learned.push({
                    emitArr.push({
                        card: row.item.card,
                        status: row.q_state === 0 ? 'learned' : 'trashed',
                        id: row.id,
                        match_type: row.match_type
                    })
                })
            }
            this.$emit('learned-item', emitArr)
            if (failure && failure.length > 0) {
                const errMessage = `Error adding cards: ${failure.map((i) => i.card).toString()}.\nMost likely due to duplicate items. Check dev console for more info.`
                SendUserAlert(errMessage, 'alert-warning')
                console.warn('Error report', failure)
                console.log('Error log', JSON.stringify(failure), JSON.stringify(dat.warning))
                return false
            }
            return true
        },
        //  SHORTHAND
        navBadgeClick(item) {
            console.log('do something with the nav', item)
        },
    },
    props: {
        //  this is an array of objects so transformations will be two way
        hotkeysActive: {
            default: false
        },
        propWords: {
            type: Object,
            default() {
                console.log('No propWords found', 'this is an error.')
                return {
                    list: [{
                        word: "顔",
                        freq: "134",
                        match: "exact",
                        in_wk: null,
                        in_user: null,
                        entries: [{
                            entry: "顔", reading: "かお", definition: ["face", "visage", " look", "expression", "countenance", " honor", "honour", "face", " influence", "notoriety"], pos: "n", somethingelse: 721
                        }]
                    }],
                    modified: false
                }
            }
        },
        opts: {
            type: Object,
            default() {
                return {
                    freq: 0,
                    wk: 0,
                    usuallyKanaOverride: false,
                    interface: 'streamlined',
                    lists: []
                }
            }
        },
        learned: {
            type: Array,
            default() {
                return []
            }
        },
        ignoreList: {
            type: Object,
            default() {
                return []
            }
        }
    },
    watch: {
        propWords: {
            handler(n) {
                //  this is necessary but why?
                this.words = n.list
                if (n.modified === true) this.resetUi()
            },
            immediate: true
        }
    },
    data() {
        return {
            wanakana,
            words: this.propWords.list, //  I guess this doesnt sync
            ui: {
                activeIndex: 0,
                activeEntry: 0,
                learned: [], //  needs to move?
            },
            active: null
        }
    },
    computed: {
        Keymap,
        navWords() {
            //  active = the index that will end up highlighted
            const { activeIndex } = this.ui
            const ret = {
                active: 0,
                arr: []
            }
            if (activeIndex === 0) {
                ret.arr = this.words.slice(activeIndex, activeIndex + 3)
            } else if (activeIndex === 1) {
                ret.active = 1
                ret.arr = this.words.slice(activeIndex - 1, activeIndex + 3)
            } else {
                ret.active = 2
                ret.arr = this.words.slice(activeIndex - 2, activeIndex + 3)
            }

            return ret
        },
        numEntriesSelected() {
            return this.active.entries.filter((i) => i.selected).length
        }
    }
}
</script>

<style lang="sass" scoped>
.phonetic-counter
    position: absolute
    top: -5px
    right: -5px
    font-size: 10px
</style>
