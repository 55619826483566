export default {
    generateAList: {
        header: 'Generate a List',
        body: 'Select an item from the dropdown to add it.<br>You can combine up to three lists.<br>Multiple lists will create a vocabulary list containing the intersect of words that appear on the first (primary) list.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    setOptions: {
        header: 'Setting List Options',
        body: 'Change list settings such as minimum word frequency by modifying values on the sidebar (desktop) or by clicking the <i class="fas fa-cog"></i> icon next to the "generate list" button (mobile).'
    },
    listInteraction: {
        header: 'Interacting with Lists',
        body: '1. Add a word if you would like to learn it.<br>2. Mark a word as known to remove it from all vocabulary lists on the website.<br>3. Ignore a word to not see it anymore for this primary book.<br>4. You can add a kanji by clicking on any kanji in a word.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    keyboardShortcuts: {
        header: 'Keyboard Shortcuts',
        body: '(1): Add word<br>(2): Mark as known<br>(3): Ignore word<br>(a/d): Cycle through words<br>(tab): Cycle through entries<br>(enter) Change to active entry.'
    },
    itemLearned: {
        header: 'Learning Words',
        body: 'You can interact with added words on the lessons page. There, quizzing yourself on a word and getting it right will cause it to enter your spaced repetition cycle, where it will periodically be available for review. Getting it wrong will return it as a lesson.',
        buttons: [{ color: 'btn-success', text: 'Go to lessons', action: () => { window.location = "#/lessons/"; } }],
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    thisIsAPreview: {
        header: 'This is a Preview',
        body: 'This is a small 100 item preview of the list. Since you\'re not logged in it will be impossible to add/trash items.',
        headerStyle: 'bg-danger',
        bodyStyle: 'bg-light bg-gradient text-dark'
    }
}
