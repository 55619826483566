<template>
    <div v-cloak id="vueApp" style="min-height:1200px">
        <!-- Toasts -->
        <Toasts v-on:hide-toasts="1 == 1" :toasts="toasts" v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]" />
        <!-- modal version of sidebar (visible as long as triggered) -->
        <div class="modal" tabindex="-1" id="side_modal" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Options</h5>
                        <span class="fa-stack clickable" data-bs-dismiss="modal" aria-lable="Close">
                            <i class="fas fa-square fa-stack-2x"></i>
                            <i class="fas fa-times fa-stack-1x"></i>
                        </span>
                    </div>
                    <div class="modal-body">
                        <Sidebar :ui="ui" :words="words" :opts="opts" :flags="flags" :ignoreList="currentIgnoreList"
                            v-on:tutorial-off="toggleTutorial()" v-on:reset-ignores="resetCurrentIgnoreList()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- full version of side (visible on certain screen sizes) -->
            <div id="side" class="d-block d-md-none col-12 marg-big">
                <Navigate slot="navigation" :ui="ui" :listIsLoaded="words.length > 0"
                    v-on:change-active-tab="changeActiveTab($event)" />
            </div>
            <div id="side" class="d-none d-md-block col-3 row marg-big">
                <Sidebar :ui="ui" :words="words" :opts="opts" :flags="flags" :ignoreList="currentIgnoreList"
                    v-on:change-active-tab="changeActiveTab($event)" v-on:reset-ignores="resetCurrentIgnoreList()"
                    v-on:tutorial-off="toggleTutorial()">
                    <Navigate slot="navigation" :ui="ui" :listIsLoaded="words.length > 0"
                        v-on:change-active-tab="changeActiveTab($event)" />
                    <h2 slot="heading" class="marg-small">Threshold Options</h2>
                    <h2 slot="heading2" class="marg-small">UI Options</h2>
                </Sidebar>
            </div>
            <div id="main" v-if="ui.activeTab === 'books'" class="col-12 col-sm-9 mx-auto">
                <!--content-->
                <div class="row pad-big loadable">
                    <Spinner v-if="flags.loading" :badge="'bg-success'" :message="'Loading'" />
                    <!-- pick books -->
                    <Selection class="col-12 book-card pad-big marg-big" :gettingloaded="flags.loading ? 1 : 0"
                        :books="books"
                        :msg="opts.lists.length == 0 ? 'Select a book' : 'Selecting additional books will make an overlapping list'"
                        v-on:book-changed="setBook($event)">
                    </Selection>
                    <div v-if="opts.lists.length > 0" id="book_selector"
                        class="col-12 book-card align-center pad-big marg-big" :gettingloaded="flags.loading ? 1 : 0">
                        <div class="row pad-big d-flex justify-center">
                            <Cover class="col-10 col-md-4 marg-big" v-for="(items, indx) in opts.lists" :items="items"
                                :indx="indx" :key="indx + 'a'" v-on:set-primary="setAsPrimaryBook($event)"
                                v-on:remove-book="removeBook($event)" />
                        </div>
                    </div>
                    <!-- generate a list -->
                    <Generate v-if="opts.lists.length !== 0"
                        class=" col-12 col-md-8 mx-auto book-card align-center pad-big marg-big"
                        :gettingloaded="flags.loading ? 1 : 0" v-on:generate-list="getList()">
                        <!-- toggles modal sidebar -->
                        <div class="col d-md-none d-flex flex-column" slot="sidebar-modal-toggle">
                            <span class="fa-stack" style="margin: auto;" @click="openSidebar()">
                                <i class="fas fa-square fa-stack-2x"></i>
                                <i class="fas fa-cog fa-stack-1x"></i>
                            </span>
                        </div>
                        <!-- msg -->
                        <span slot="msg">Generate List{{ opts.lists.length > 1 ? ` using ${opts.lists.length} books` :
                            '' }}</span>
                    </Generate>
                </div>
            </div>
            <div v-show="ui.activeTab === 'vocab'" class="col-12 col-sm-9 mx-auto">
                <!-- show words from generated list -->
                <List class="row pad-big" :hotkeysActive="ui.activeTab === 'vocab'"
                    :propWords="{ list: words, modified: flags.modified }" :opts="opts" :learned="ui.learned"
                    :ignoreList="ignoreList" v-on:ui-was-reset="flags.modified = false"
                    v-on:ignored-item="ignoreWord($event)" v-on:learned-item="markItemsLearned($event)"
                    v-on:undo-learn="unmarkItemsLearned($event)" v-on:clear-from-ignore="unignoreItem($event)">
                    <!-- toggles modal sidebar -->
                    <div class="col d-md-none marg-small" slot="sidebar-modal-toggle-outer">
                        <span class="fa-stack" @click="openSidebar()">
                            <i class="fas fa-square fa-stack-2x" :class="ui.learned.length > 0 ? 'c-purple' : ''"></i>
                            <i class="fas fa-cog fa-stack-1x"></i>
                        </span>
                    </div>
                </List>
            </div>
            <div v-show="ui.activeTab === 'grid'" class="col-12 col-sm-9 mx-auto">
                <!-- show words from generated list in grid format -->
                <Grid class="row pad-big" :hotkeysActive="ui.activeTab === 'grid'"
                    :propWords="{ list: words, modified: flags.modified }" :opts="opts" :learned="ui.learned"
                    :ignoreList="ignoreList" v-on:ui-was-reset="flags.modified = false"
                    v-on:ignored-item="ignoreWord($event)" v-on:learned-item="markItemsLearned($event)"
                    v-on:undo-learn="unmarkItemsLearned($event)" v-on:clear-from-ignore="unignoreItem($event)">
                </Grid>
            </div>
            <div v-if="ui.activeTab === 'help'" class="col-12 col-md-9 mx-auto">
                <Help class="row pad-big" />
            </div>
            <!--main-->
        </div>
        <!-- preload all kanji -->
        <div lang="ja" style="opacity: 0; font-size: 1px">{{ fontFixPreload }}</div>
    </div>
</template>

<script>
import { Modal as Bmodal } from 'bootstrap'
import Lockr from 'lockr'
import { isKanji, isHiragana } from 'wanakana';
import { UseAPI, HandleRequestFail, SendUserAlert } from '@/assets/common'
import TOAST_CONFIG from '@/assets/js/toastsConfig/vocabulary';
import getSavedOptions from '@/assets/js/getSavedOptions';
import { SS } from '@/assets/constants'
import Spinner from '../Spinner.vue'
import Selection from './Select/Select.vue'
import Cover from './Select/Cover.vue'
import Generate from './Select/Generate.vue'
import List from './List/List.vue'
import Grid from './Grid/Grid.vue'
import Help from './Help/Help.vue'
import Toasts from '../Shared/Toasts.vue'
import Sidebar from './Sidebar/Sidebar.vue'
import Navigate from './Sidebar/Navigate.vue'

const wanakana = { isKanji, isHiragana }

export default {
    name: "Vocabulary",
    components: {
        Cover,
        Selection,
        Generate,
        List,
        Grid,
        Help,
        Spinner,
        Sidebar,
        Navigate,
        Toasts,
    },
    computed: {
        fontFixPreload() {
            const { words } = this
            if (!words || words.length === 0) return ""
            return words.map((i) => i.word).join('')
        },
        currentIgnoreList() {
            try {
                if (this.opts.lists.length === 0) return null
                return this.ignoreList.getItem(this.opts.lists[0].id)
            } catch (e) {
                console.log('currentIgnoreList not set properly', e)
                return null
            }
        }
    },
    data() {
        return {
            modals: {
                nav: null
            },
            toasts: [],
            possibleToasts: null, // set at created()
            books: [],
            words: [],
            opts: {
                freq: 0,
                wk: 0,
                //  interface: 'streamlined',
                tutorial: true,
                genOnLoad: false,
                allFrequencies: false,
                usuallyKanaOverride: true,
                lists: []
            },
            flags: {
                optionsInitiated: false, // has done initial options setup in created()
                loaded: false,
                loading: false, //  used for spinner animation
                modified: false, // has word list been modified?
                made: false,
                selectedBookIndex: 0,
                //  selectedBook: '',
                maxSelectedBooks: 3,
                addedItems: false,
                //  notableConj: ['conj', 'exp', 'pn', 'uk', 'vt', 'vi', 'hon'],
                lockrPrefix: 'flfl_list_',
                lastSearch: null
            },
            ui: {
                activeTab: 'books',
                learned: [],
                learnedCnt: 0,
                unknown: 0,
                unique: 0,
                benchmark: 0,
                ignored: 0
            },
            ignoreList: {
                retrievedList: null,
                version: 1,
                newList(list, version) {
                    return ({
                        list,
                        items: [],
                        lastModified: new Date(),
                        version
                    })
                },
                getItem(listIdentifier) {
                    // get the items of a specific list
                    try {
                        //  this was changed from == to ===
                        const found = this.retrievedList.items.findIndex((i) => i.list === listIdentifier)
                        if (found === -1) return []
                        return this.retrievedList.items[found].items
                    } catch (e) {
                        SendUserAlert(`Fatal error occurred retrieving ignore list... ${e} Clearing items to prevent error.`)
                        console.warn(`Fatal error occurred retrieving ignore list... ${e} Clearing items to prevent error.`)
                        return []
                    }
                },
                validateListVersion(item) {
                    if (!('version' in item)) return false
                    if (item.version === this.version) return true
                    return false
                },
                saveList(obj, lockr) {
                    if (!('set' in lockr)) return false
                    lockr.set('ignoreList', obj)
                    console.log('Ignore list updated')
                    return lockr.get('ignoreList')
                },
                removeItem(word, list) {
                    const foundIndex = list.findIndex((i) => i === word)
                    return list.splice(foundIndex, 1)
                },
                clearList(lockr) {
                    if (!('set' in lockr)) return false
                    lockr.rm('ignoreList')
                    console.log('Ignore list deleted')
                    return lockr.get('ignoreList')
                }
            }
        }
    },
    methods: {
        errorRep(dat, id) {
            if (dat.items.length <= 0) {
                console.log(`error with ${id}`)
                console.log(dat.options)
            }
        },
        openSidebar() {
            this.modals.nav.toggle()
        },
        init(dat) {
            const { items } = dat
            this.errorRep(dat, 'init')
            for (let i = 0; i < items.length; i += 1) {
                this.books.push(items[i])
            }
            this.flags.loaded = true
            return true
        },
        saveSettings() {
            const CONTEXT = 'lists'
            if (SS.get(SS.PUBLIC_API)) {
                // alt for public api
                SS.set(SS.USER_SETTINGS_COOKIE, {
                    ...SS.get(SS.USER_SETTINGS_COOKIE) || {},
                    [CONTEXT]: { ...this.opts }
                })
                return
            }
            const body = JSON.stringify({
                context: CONTEXT,
                val: JSON.stringify(this.opts)
            })
            if (this.flags.optionsInitiated) {
                UseAPI('/create/user-settings', {
                    method: "PUT",
                    body
                })
                    .then((result) => {
                        SendUserAlert('Settings saved to server', 'alert-success')
                        //  remove cookie and refresh, so that it doesn't hold up future page loads
                        SS.remove(SS.USER_SETTINGS_COOKIE)
                        getSavedOptions()
                    })
                    .catch((result) => {
                        SendUserAlert('Error saving user settings to server', 'alert-danger')
                        console.log('Error saving user settings to server', result)
                    })
            }
        },
        toggleTutorial() {
            this.opts.tutorial = !this.opts.tutorial
            this.toasts = []
            this.saveSettings()
        },
        setBook(bookId) {
            const found = this.books.find((i) => i.id === bookId)
            if (!found) return false

            const indx2 = this.flags.selectedBookIndex
            if (!found) {
                SendUserAlert(`Book with id ${bookId}not found`, 'alert-danger');
                return false
            }

            if (this.opts.lists.map((i) => i.id).includes(bookId)) {
                SendUserAlert('Book already selected', 'alert-warning');
                return false
            }
            //  make sure its reactive by not setting directly
            //  this.opts.lists[indx2] = found
            if (this.opts.lists.length < 3) {
                this.opts.lists.push(found)
            } else {
                console.log('alt track')
                this.opts.lists.splice(2, 1, found)
            }
            if (this.opts.lists.length < this.flags.maxSelectedBooks) {
                this.flags.selectedBookIndex += 1
            }
            return true
        },
        getList() {
            /* uses window.app and window.listSidebar */
            const finalList = this.opts.lists.map((item) => item.vocab_list)
            const ignoreList = this.currentIgnoreList || []
            const vm = this
            const primaryList = this.opts.lists[0]
            /* rename */
            const opts = {
                lists: finalList.toString(),
                freq: this.opts.freq,
                wk: this.opts.wk,
            }
            const method = SS.get(SS.PUBLIC_API) ? "GET" : "POST"
            const apiOpts = {
                method, queryParameters: `list=${opts.lists}&freq=${opts.freq}&wk=${opts.wk}`
            }
            if (method === "POST") apiOpts.body = JSON.stringify({ ignores: ignoreList })
            //  set loading state
            this.flags.loading = true
            UseAPI("/get/list", apiOpts)
                .then((dat) => {
                    console.log('found items', dat)
                    if (dat.items.length === 0) {
                        SendUserAlert('List combination has no words for you to learn')
                        return
                    }
                    this.setWords(dat)
                    this.ui.activeTab = 'vocab'
                    this.flags.made = true
                    console.log('this words', this.words)
                })
                .catch((dat) => {
                    SendUserAlert('Error getting list, see console', 'alert-danger')
                    console.log('ERROR: retrieved', dat)
                    HandleRequestFail(dat)
                })
                .then(() => {
                    this.ui.ignored = ignoreList.length
                    this.flags.lastSearch = primaryList
                    this.flags.loading = false
                    //  clear current toasts before adding new ones or its overwhelming
                    this.toasts = []
                    if (this.opts.tutorial) {
                        this.toasts.push(this.possibleToasts.listInteraction)
                        this.toasts.push(this.possibleToasts.keyboardShortcuts)
                    }
                })
                .then(() => {
                    //  handle public
                    if (SS.get(SS.PUBLIC_API)) this.toasts.push(this.possibleToasts.thisIsAPreview)
                })
        },
        setWords(dat) {
            console.log('setting words')
            this.errorRep(dat, 'setWords')
            const { items } = dat
            const words = []
            if (!items) {
                console.log('error settings words\napp will probably not function correctly', dat)
                return
            }
            items.forEach((i) => {
                i.entries.forEach((entry, entryIndex) => {
                    if (entry.pos.indexOf('uk') !== -1) entry.somethingelse += 50
                    if (wanakana.isHiragana(entry.entry)) entry.somethingelse += 150
                    entry.definition.forEach((def, index) => {
                        // eslint-disable-next-line no-param-reassign
                        def = def.replace(/,/g, ', ')
                    })
                    entryIndex === 0 ? entry.selected = 1 : entry.selected = 0
                })
                i.entries.sort((a, b) => b.somethingelse - a.somethingelse)
                i.ignored = false
                words.push(i)
            })

            if ('modules' in dat && 'counts' in dat.modules) {
                this.ui.benchmark = dat.modules.counts.benchmark
                this.ui.unknown = dat.modules.counts.unknown
                this.ui.unique = dat.modules.counts.unique
            }
            this.words = words
            //  this.resetUi()
            this.flags.modified = true
            this.flags.loading = true
            try {
                this.saveSettings()
            } catch (e) {
                console.log('error saving settings', e)
            }
        },
        changeActiveTab(tab) {
            const permittedTabValues = ['books', 'vocab', 'grid', 'help']
            if (
                (tab === 'vocab' || tab === 'grid')
                && (!this.flags.loaded || this.words.length <= 0)
            ) {
                console.log('nup')
                SendUserAlert('No list generated yet', 'alert-warning')
                return false
            }
            if (permittedTabValues.includes(tab)) this.ui.activeTab = tab
            return true
        },
        setAsPrimaryBook(indx) {
            const { lists } = this.opts
            const newPrimary = lists[indx]
            lists.splice(indx, 1)
            lists.unshift(newPrimary)
            SendUserAlert(`Primary book changed to ${newPrimary.title}`, 'alert-success')
        },
        removeBook(indx) {
            const { lists } = this.opts
            lists.splice(indx, 1)
            this.flags.selectedBookIndex = lists.length
        },
        getIgnoredList() {
            const lockr = Lockr
            lockr.prefix = this.flags.lockrPrefix
            let found = lockr.get('ignoreList')
            console.log('found ignore list', found)
            if (!found) {
                console.log('no ignore list found', found)
                lockr.set('ignoreList', ({
                    items: [],
                    opts: {
                        lastModified: new Date()
                    }
                }))
                found = lockr.get('ignoreList')
            }
            return found
        },
        ignoreWord(word) {
            const listIdentifier = this.flags.lastSearch.id
            const { retrievedList } = this.ignoreList
            const { newList, saveList } = this.ignoreList
            if (!word || !listIdentifier || !retrievedList) return false

            const lockr = Lockr
            lockr.prefix = this.flags.lockrPrefix
            if (!('items' in retrievedList)) {
                SendUserAlert('Improperly configured ignore list', 'alert-danger')
                return false
            }
            let listIndex = retrievedList.items.findIndex((i) => i.list === listIdentifier)
            if (listIndex === -1) {
                console.log('Creating new list for stuff')
                retrievedList.items.push(newList(listIdentifier, this.ignoreList.version))
                listIndex = retrievedList.items.length - 1
            }
            const list = retrievedList.items[listIndex]
            list.items.push(word)
            list.lastModified = new Date()
            retrievedList.lastModified = new Date()
            // save list
            saveList(retrievedList, lockr)
            return true
        },
        //  $emit handling
        markItemsLearned(items) {
            if (!Array.isArray(items)) {
                console.log('Err', 'markItemsLearned expected Array, got', items)
                return
            }
            items.forEach((row) => {
                this.ui.learned.push(row)
            })
            if (this.ui.learned.some((i) => i.status === 'learned') && !this.flags.addedItems) {
                if (this.opts.tutorial) this.toasts.push(this.possibleToasts.itemLearned)
                this.flags.addedItems = true
            }
        },
        unmarkItemsLearned(itemIndex) {
            this.ui.learned.splice(itemIndex, 1)
        },
        unignoreItem(item) {
            const { ignoreList } = this
            const lockr = Lockr
            lockr.prefix = this.flags.lockrPrefix
            if (!('items' in ignoreList.retrievedList)) {
                SendUserAlert('Improperly configured ignore list', 'alert-danger')
                return
            }
            const list = ignoreList.getItem(this.opts.lists[0].id)
            ignoreList.removeItem(item.card, list)
            ignoreList.saveList(this.ignoreList.retrievedList, lockr)
        },
        resetCurrentIgnoreList() {
            try {
                const lockr = Lockr
                const found = this.ignoreList.retrievedList.items.find((i) => i.list === this.opts.lists[0].id)
                if (found) {
                    found.items = []
                    this.ignoreList.saveList(this.ignoreList.retrievedList, lockr)
                    SendUserAlert('List successfully cleared', 'alert-success')
                }
            } catch (e) {
                SendUserAlert('Error clearing ignore list. Check console.', 'alert-danger')
                console.warn(e)
            }
        },
        runClearIgnoreList() {
            this.ignoreList.clearList(Lockr)
        }
    },
    watch: {
        // eslint-disable-next-line func-names
        'opts.genOnLoad': function () {
            this.saveSettings()
        },
        // eslint-disable-next-line func-names
        'opts.tutorial': function () {
            this.saveSettings()
        },
        books(arr) {
            const listParam = this.$route.query.list
            if (listParam) {
                const book = this.books.find((i) => i.vocab_list === listParam)
                this.opts.lists.push(book)
            }
        }
    },
    mounted() {
        const setupModals = () => {
            const myModal = new Bmodal(document.getElementById('side_modal'), {})
            this.modals.nav = myModal
            console.log('setup modals', myModal)
        }
        setupModals()
    },
    async created() {
        console.log('%cCreated Vocabulary', window.ConsoleStyles.createdComponent, this)

        //  AJAX get list of books
        const method = SS.get(SS.PUBLIC_API) ? "GET" : "POST"
        const getBooks = () => UseAPI('/get/booksWithFlags', { method })
            .then((dat) => Promise.resolve(dat))
            .catch((dat) => {
                HandleRequestFail(dat)
                return Promise.reject()
            })

        const loadIgnores = () => {
            const tempIgnoreList = this.getIgnoredList()
            this.ignoreList.retrievedList = tempIgnoreList
        }

        const handleGenOnLoad = () => {
            const gen = () => {
                try {
                    this.getList()
                } catch (e) {
                    console.log('Issue automatically generating list', e)
                    SendUserAlert(`Issue automatically generating list\n${e}\nTurning auto generate off`, 'alert-danger')
                }
            }
            this.flags.loading = true //    set earlier so it looks cleaner
            setTimeout(gen, 1000)
        }

        this.possibleToasts = TOAST_CONFIG;
        //  set book tutorial if opts.tutorial is enabled
        const setInitTutorial = () => {
            this.toasts.push(this.possibleToasts.generateAList)
            this.toasts.push(this.possibleToasts.setOptions)
        }

        loadIgnores()

        const CONTEXT = 'lists'
        getSavedOptions(CONTEXT) /* load user settings before */
            .then((resultingOpts) => {
                const listsSet = !!this.$route.query.list
                console.log('%cgetUserSettings', window.ConsoleStyles.routine, resultingOpts)
                Object.keys(resultingOpts).forEach((i) => {
                    if (i !== 'lists') {
                        // why is this vm?
                        this.opts[i] = resultingOpts[i]
                    } else if (!listsSet) {
                        // is list and listsSet is not true
                        resultingOpts[i].forEach((aBook) => {
                            this.opts.lists.push(aBook)
                        })
                    }
                })
            })
            .catch((result) => {
                console.log('Lists could not load user settings', result)
                if (!SS.get(SS.PUBLIC_API)) {
                    console.log('getUserSettings', result)
                    SendUserAlert(`Failed to fetch user settings: ${result}`, 'alert-warning')
                }
            })
            .then(() => getBooks()) /* handle actual loading */
            .then((res) => this.init(res))
            .catch((res) => {
                console.log('There was an issue retreieving books', res)
                return false
            })
            .then(() => {
                this.flags.selectedBookIndex = this.opts.lists.length < 3 ? this.opts.lists.length : 3

                //  if opts.genOnLoad = true, load list automatically
                if (this.opts.genOnLoad) handleGenOnLoad()
                if (!this.opts.genOnLoad && this.opts.tutorial) setInitTutorial()
                this.flags.optionsInitiated = true
            })
    }
}
</script>

<style lang="sass">
body
    &[mode="light"]
        #vueApp
            .user-focus
                .bord
                    border-color: orange
    &.dark
        #vueApp
            .user-focus
                .bord
                    //border-color: #222
                    border-color: orange
                #word
                    //background-color: #986cbf
                    color: #ffffff!important
                    //box-shadow: 0 2px 8px #2222
                    //border: 1px solid #222
                    .hover_kanji, .c-purple
                        color: #fff!important
                    .hover_kanji:hover
                        color: #ce0000!important
            .c-blue
                color: rgb(255, 71, 71)!important
#vueApp
    #side
        .navigation
            div
                min-height: 2rem
                display: flex
                align-items: center
                margin: .15em 0
                span
                    margin: auto
    .book-card
        transition: none
    .badge
        min-width: 3em
    .header
        &.badge:first-child
            margin: .35em 0
        &.badge
            min-width: 6em
            margin: .35em
        &.badge:first-of-type
            margin-left: 0
    .user-focus
        .bord
            text-align: center
            border: 2px solid #eee
            border-radius: 8px
            display: flex
            align-items: center
            justify-content: center
    img.cover
        max-width: 200px
        height: auto
        box-shadow: 0px 2px 5px #0e0e0e
</style>
