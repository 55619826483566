<template>
<div>
    <span>
        <span
            class="badge bg-jet pad-small"
            v-for="(defs, indx) in items.definition.slice(0,3)"
            :key="indx+'def'"
            :title="defs.length > maxLen ? defs : ''"
        >
            {{displayDef(defs)}}
        </span>
        <span v-if="items.definition.length > 3"
            class="badge bg-jet"
            v-tippy
            :content="items.definition.slice(3, 99).join(', ')"
        >
            ...
        </span>
    </span>
</div>
</template>

<script>
import VueTippy, { TippyComponent } from "vue-tippy";

export default {
    name: 'Definitions',
    props: ['entries', 'index'],
    computed: {
        items() {
            return this.entries
        }
    },
    data() {
        return {
            debug: true,
            maxLen: 30
        }
    },
    created() {
        if (this.debug) console.log('%cCreated definition', window.ConsoleStyles.createdComponent, this)
    },
    methods: {
        displayDef(str) {
            if (str.length > this.maxLen) return `${str.trim().slice(0, 25)}…`
            return str.trim()
        }
    }
}
</script>
