<template>
<span>
    <span
        v-if="0 === 1"
        class="badge bg-info"
        v-tippy
        content="Parts of speech for selected entry"
    >
        speech
    </span>
    <span class="badge bg-purple pad-small"
        v-for="(pos, indx) in transitivity"
        :key="indx+'pos2'"
    >
        {{pos}}
    </span>
    <span class="badge bg-success pad-small"
        v-for="(pos, indx) in [...formality, ...usage]"
        :key="indx+'pos1'"
    >
        {{pos}}
    </span>
    <span class="badge bg-secondary pad-small"
        v-for="(pos, indx) in speech"
        :key="indx+'pos3'"
    >
        {{pos}}
    </span>
    <span class="badge bg-warning pad-small"
        v-for="(pos, indx) in warning"
        :key="indx+'pos4'"
    >
        {{pos}}
    </span>
</span>
</template>

<script>
    export default {
        name: 'PartsOfSpeech',
        created() {
            if (this.debug) console.log('%ccreated Parts of Speech', window.ConsoleStyles.createdComponent, this)
        },
        props: {
            pos: {
                type: Array,
                default() {
                    return []
                }
            },
        },
        computed: {
            formality() {
                const { pos } = this
                return this.useNotableConjFilter(pos, 'formality')
            },
            transitivity() {
                const { pos } = this
                return this.useNotableConjFilter(pos, 'transitivity')
            },
            usage() {
                const { pos } = this
                return this.useNotableConjFilter(pos, 'usage')
            },
            warning() {
                const { pos } = this
                return this.useNotableConjFilter(pos, 'warning')
            },
            speech() {
                const { pos } = this
                return this.useNotableConjFilter(pos, 'speech')
            },
        },
        methods: {
            useNotableConjFilter(pos, listToUse) {
                if (listToUse in this.notableConj) {
                    return pos.filter((i) => this.notableConj[listToUse].includes(i))
                }
                return []
            }
        },
        data() {
            return {
                debug: false,
                notableConj: {
                    //  http://icecube.berkeley.edu/~dima/stuff/japanese/edict_doc.html
                    formality: ['col', 'fam', 'fem', 'hon', 'hum', 'male', 'm-sl', 'pol', 'sl', 'vulg'],
                    transitivity: ['v1', 'v5', 'v5u', 'v5u-s', 'v5k', 'v5g', 'v5s', 'v5t', 'v5n', 'v5b', 'v5m', 'v5r', 'v5k-s', 'v5z', 'v5aru', 'v5uru', 'vi', 'vs', 'vt', 'vs-s'],
                    usage: ['abbr', 'adj-i', 'adj-na', 'adj-no', 'adj-pn', 'adj-t', 'arch', 'aux-v', 'conj', 'exp', 'gikun', 'gram', 'id', 'int', 'pref', 'suf', 'uk', 'num'],
                    warning: ['obs', 'obsc', 'oK', 'ok'],
                    speech: ['adj', 'adv', 'n', 'n-adv', 'n-t', 'n-suf', 'aux', 'prt'],
                    unused: ['ateji', 'ek', 'iK', 'ik', 'io', 'MA', 'neg', 'neg-v', 'uK', 'vk']
                },
            }
        }
    }
</script>
